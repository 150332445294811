const isLocalhost = window.location.origin.indexOf('localhost') >= 0;
const apiUrl = isLocalhost ? 'http://localhost/digital-city-c5/index.php' : window.location.origin;

export const addToFav = (id, cb) => {
	$.ajax({
		type: 'POST',
		data: {
			id,
		},
		url: `${apiUrl}/api/v1/favourites/add/`,
		success: cb,
	});
};

export const removeFromFav = (id, cb) => {
	$.ajax({
		type: 'POST',
		data: {
			id,
		},
		url: `${apiUrl}/api/v1/favourites/delete/`,
		success: cb,
	});
};

export const attachFavHandler = customCallback => {
	if ($('.add-to-favourite').length) {
		$('.add-to-favourite')
			.off('click')
			.on('click', e => {
				e.stopPropagation();
				const $this = $(e.currentTarget);
				const id = $this.data('id');
				const isActive = $this.hasClass('active');
				if (isActive) {
					removeFromFav(id, () => {
						$this.removeClass('active');
						if (customCallback) {
							customCallback();
						}
					});
				} else {
					addToFav(id, () => {
						$this.addClass('active');
						if (customCallback) {
							customCallback();
						}
					});
				}
			});
	}
};

export const downloadLazyImage = (src, callback) => {
	new Promise((resolve, reject) => {
		const image = new Image();
		image.src = src;
		image.addEventListener('load', () => {
			// setTimeout(() => {
			callback(true);
			resolve(true);
			// }, 5000)
		});
	});
};

export const parseQS = querystring => {
	// remove any preceding url and split
	querystring = querystring.substring(querystring.indexOf('?') + 1).split('&');
	let params = {},
		pair,
		d = decodeURIComponent;
	// march and parse
	for (let i = querystring.length - 1; i >= 0; i--) {
		pair = querystring[i].split('=');
		params[d(pair[0])] = d(pair[1] || '');
	}
	return params;
};

export const objectToFormData = obj =>
	Object.keys(obj).reduce((formData, key) => {
		formData.append(key, obj[key]);
		return formData;
	}, new FormData());
