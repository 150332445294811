import './libs/gsap';
import Header from './components/Header';
import {
  objectToFormData,
  attachFavHandler,
  parseQS
} from './components/utils';

import './components/NewsSlider';
import './components/CommonSlider';
import './components/InViewport';
import './components/Search';
import './components/Sidebar';
import Maps from './components/Maps';

if ($('.chosen-select, .contact-form select').length) {
  require('./components/chosen');
  $('.chosen-select, .contact-form select').chosen({
    width: 311,
    disable_search_threshold: 20
  });
}

export default new (class App {
  constructor() {
    this.setDomMap();
    this.previousScroll = 0;
    const isLocalhost = window.location.origin.indexOf('localhost') >= 0;
    this.apiUrl = isLocalhost
      ? 'http://localhost/digital-city-c5/index.php'
      : window.location.origin;
    // dom ready shorthand
    $(() => {
      this.domReady();
    });
  }

  domReady = () => {
    this.initComponents();
    this.handleUserAgent();
    this.windowResize();
    this.bindEvents();
    this.handleSplashScreen();
  };

  initComponents = () => {
    new Header({
      header: this.header,
      htmlBody: this.htmlBody
    });
  };

  setDomMap = () => {
    this.window = $(window);
    this.htmlNbody = $('body, html');
    this.html = $('html');
    this.htmlBody = $('body');
    this.siteLoader = $('.site-loader');
    this.header = $('header');
    this.siteBody = $('.site-body');
    this.footer = $('footer');
    this.gotoTop = $('#gotoTop');
    this.gRecaptcha = $('.g-recaptcha');
    this.wrapper = $('.wrapper');
    this.pushDiv = this.wrapper.find('.push');
    this.mapContainer = $('.single-map');
    this.inputs = $('input, textarea').not('[type="checkbox"], [type="radio"]');
    this.newsLetterForm = $('.newsletter-form');
    this.faq = $('.faq .item');
    this.transportationList = $('.transportation-logo-list');
    this.transportationMapList = $('.transportation-map-list');
    this.registerScroll = $('.register-scroll');

    this.mapHandler = this.mapContainer.length
      ? new Maps({
          forceDraw: true,
          element: $('.single-map'),
          lat: $('.single-map').data('lat'),
          lng: $('.single-map').data('lng')
        })
      : null;
  };

  sendForm = data => {
    const isLocalhost = window.location.origin.indexOf('localhost') >= 0;
    const url = isLocalhost
      ? 'http://localhost/digital-city-c5/index.php'
      : window.location.origin;
    $.ajax({
      type: 'POST',
      data: objectToFormData(data),
      processData: false,
      contentType: false,
      cache: false,
      enctype: 'multipart/form-data',
      url: `${url}/api/v1/contact-us/`,
      success: this.successHandler
    });
  };

  successHandler = response => {
    console.log(response);
  };

  bindEvents = () => {
    attachFavHandler();
    // Window Events
    this.window.resize(this.windowResize).scroll(this.windowScroll);

    var _tripleClickTimer = 0;
    var _mouseDown = false;

    document.onmousedown = function() {
        _mouseDown = true;
    };

    document.onmouseup = function() {
        _mouseDown = false;
    };

    document.ondblclick = function DoubleClick(evt) {
        ClearSelection();
        window.clearTimeout(_tripleClickTimer);
        
        //handle triple click selecting whole paragraph
        document.onclick = function() {
            ClearSelection();
        };
        
        _tripleClickTimer = window.setTimeout(RemoveDocumentClick, 1000);
    };

    function RemoveDocumentClick() {
        if (!_mouseDown) {
            document.onclick = null; 
            return true;
        }
        
        _tripleClickTimer = window.setTimeout(RemoveDocumentClick, 1000);
        return false;
    }

    function ClearSelection() {
        if (window.getSelection)
            window.getSelection().removeAllRanges();
        else if (document.selection)
            document.selection.empty();
    }

    // General Events
    const $container = this.wrapper;

    $container.on('click', '.disabled', () => false);

    // Specific Events
   

    this.transportationList.on('click', '.transportation-logo', e => {
      const $this = $(e.currentTarget);
      const id = $this.data('id');
      $this.removeClass('active');
      $this.siblings().removeClass('active');
      $this.addClass('active');

      this.transportationMapList
        .find('.transportation-map')
        .removeClass('active');
      this.transportationMapList
        .find(`div[data-id="${id}"]`)
        .addClass('active');
    });

    this.registerScroll.on('click', () => {
      const $regForm = $('.registration-form');
      if ($regForm.length) {
        this.htmlNbody.animate({
          scrollTop: $regForm.offset().top
        });
      }
    });

    this.newsLetterForm.on('submit', e => {
      e.preventDefault();
      const email = this.newsLetterForm.find('input').val();
      this.sendForm({ email });
    });

    this.faq.on('click', e => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      if ($this.hasClass('active')) {
        $this.removeClass('active');
      } else {
        $this.addClass('active');
      }
    });

    this.gotoTop.on('click', () => {
      this.htmlNbody.animate({
        scrollTop: 0
      });
    });

    this.inputs
      .on({
        focus: e => {
          const self = $(e.currentTarget);
          self.closest('.element').addClass('active');
        },
        blur: e => {
          const self = $(e.currentTarget);
          if (self.val() !== '') {
            self.closest('.element').addClass('active');
          } else {
            self.closest('.element').removeClass('active');
          }
        }
      })
      .trigger('blur');
  };

  windowResize = () => {
    this.screenWidth = this.window.width();
    this.screenHeight = this.window.height();

    // calculate footer height and assign it to wrapper and push/footer div
    if (this.pushDiv.length) {
      this.footerHeight = this.footer.outerHeight();
      this.wrapper.css('margin-bottom', -this.footerHeight);
      this.pushDiv.height(this.footerHeight);
    }
  };

  windowScroll = () => {
    const topOffset = this.window.scrollTop();

    this.header.toggleClass('top', topOffset > 300);
    this.header.toggleClass('sticky', topOffset > 600);
    if (topOffset > this.previousScroll || topOffset < 500) {
      this.header.removeClass('sticky');
    } else if (topOffset < this.previousScroll) {
      this.header.addClass('sticky');
      // Additional checking so the header will not flicker
      if (topOffset > 250) {
        this.header.addClass('sticky');
      } else {
        this.header.removeClass('sticky');
      }
    }

    this.previousScroll = topOffset;
    this.gotoTop.toggleClass(
      'active',
      this.window.scrollTop() > this.screenHeight / 2
    );
  };

  handleSplashScreen() {
    this.htmlBody.find('.logo-middle').fadeIn(500);
    this.siteLoader.delay(1500).fadeOut(500);
  }

  handleUserAgent = () => {
    // detect mobile platform
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      this.htmlBody.addClass('ios-device');
    }
    if (navigator.userAgent.match(/Android/i)) {
      this.htmlBody.addClass('android-device');
    }

    // detect desktop platform
    if (navigator.appVersion.indexOf('Win') !== -1) {
      this.htmlBody.addClass('win-os');
    }
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      this.htmlBody.addClass('mac-os');
    }

    // detect IE 10 and 11P
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > 0
    ) {
      this.html.addClass('ie10');
    }

    // detect IE Edge
    if (/Edge\/\d./i.test(navigator.userAgent)) {
      this.html.addClass('ieEdge');
    }

    // Specifically for IE8 (for replacing svg with png images)
    if (this.html.hasClass('ie8')) {
      const imgPath = '/themes/theedge/images/';
      $('header .logo a img,.loading-screen img').attr(
        'src',
        `${imgPath}logo.png`
      );
    }

    // show ie overlay popup for incompatible browser
    if (this.html.hasClass('ie9')) {
      const message = $(
        '<div class="no-support"> You are using outdated browser. Please <a href="https://browsehappy.com/" target="_blank">update</a> your browser or <a href="https://browsehappy.com/" target="_blank">install</a> modern browser like Google Chrome or Firefox.<div>'
      );
      this.htmlBody.prepend(message);
    }
  };
})();
