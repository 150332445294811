// import { TimelineMax, TweenMax, Power3 } from 'gsap/all';
import { downloadLazyImage } from './utils';

$.fn.isInViewport = function(offset) {
  var offst = offset ? offset : 100;
  var elementTop = $(this).offset().top + offst;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

class IsInViewportComponent {
  constructor() {
    this.setDomMap();
    this.setInitialState();
    this.init();
    ['resize', 'scroll'].forEach(e => {
      window.addEventListener(e, this.init);
    });
  }

  setDomMap = () => {
    this.$header = $('.app-header');
    this.$bannerText = $('.banner__text');
    this.$footer = $('.footer');
    this.$bannerIcons = $('.right-content');
    this.$containerCustom = $('.icon-image-listing .container-custom .content');
    this.$registrationForm = $('.registration-form .container-custom .content');
    this.$titleContentBlock = $('.title-left-content-right');
    this.$newsPreview = $('.news-preview-slider');
    this.lazyImage = $('.lazy-image');
    this.$inPageBanner = $('.inpage-banner');
    this.$imageListing = $('.image-listing');
    this.$commonSlider = $('.common-preview-slider');
    this.$commonImageSlider = $('.common-image-slider');
    this.$listingPageHeader = $('.page-listing-header');
    this.$newsDetail = $('.news__detail');
    this.$quotation = $('.quotation');
    this.$companyDetail = $('.company__detail');
    this.$imageListingWithText = $('.image-description-listing');
    this.$contactDetail = $('.contact__detail');
  };

  setInitialState = () => {
    const elementsToHide = [
      this.$footer,
      this.$registrationForm,
      this.$containerCustom,
      this.$bannerIcons,
      this.$commonSlider,
      this.$header,
      this.$titleContentBlock,
      this.$newsPreview,
      this.$imageListing,
      this.$bannerText,
      this.$listingPageHeader,
      this.$commonImageSlider,
      this.$newsDetail,
      this.$quotation,
      this.$companyDetail,
      this.$imageListingWithText,
      this.$contactDetail
    ];

    elementsToHide.map($el => {
      if ($el.length) {
        TweenMax.set($el, { autoAlpha: 0 }, 0);
      }
    });
  };

  checkViewport = ($el, offset) => {
    return (
      $el && $el.length && $el.isInViewport(offset) && !$el.hasClass('animated')
    );
  };

  init = () => {
    this.genericSlideUpAnimation(this.$header, 'ul li, .logo');
    this.genericSlideUpAnimation(
      this.$footer,
      '.logo, li, p, form, .logo-mobile, .by-tentwenty'
    );
    this.genericSlideUpAnimation(this.$bannerIcons, '.content-item');
    this.genericSlideUpAnimation(this.$containerCustom, '.left, .right');
    this.genericSlideUpAnimation(this.$registrationForm, '.left, .right');
    this.$imageListingWithText.each((index, el) => {
      this.genericSlideUpAnimation(
        $(el),
        '.page-list-title, p, h5, img',
        null,
        true
      );
    });
    this.$titleContentBlock.each((index, el) => {
      this.genericSlideUpAnimation(
        $(el),
        'h1, h3, .line, p, .btn, .price',
        null,
        true
      );
    });
    // this.$newsDetail.find('p').each((index, el) => {
    this.genericSlideUpAnimation(
      this.$newsDetail,
      'p, .event-block,  .page-list-header'
    );

    this.genericSlideUpAnimation(
      this.$contactDetail,
      '.page-list-header, .item-block, .content-block'
    );

    this.genericSlideUpAnimation(
      this.$companyDetail,
      '.page-list-header, .item-block, .content-block'
    );
    // });

    this.$commonImageSlider.each((index, el) => {
      this.genericSlideUpAnimation($(el), 'img');
    });
    this.$quotation.each((index, el) => {
      this.genericSlideUpAnimation($(el), 'h3');
    });

    this.genericSlideUpAnimation(
      this.$bannerText,
      'h1, .line, .breadcrumb',
      null,
      true
    );
    this.genericSlideUpAnimation(
      this.$listingPageHeader,
      '.page-list-header, .page-listing-filter, .line'
    );
    this.$inPageBanner.each((index, el) => {
      this.genericSlideUpAnimation($(el), 'h2, a');
    });

    this.$imageListing.each((index, el) => {
      this.genericSlideUpAnimation(
        $(el),
        '.page-list-header .page-list-title, .image-listing-item , a',
        null,
        true
      );
    });

    this.$commonSlider.each((index, el) => {
      this.genericSlideUpAnimation(
        $(el),
        '.page-list-header .page-list-title, .page-list-header a ,.common-slider-item, .page-list-arrow'
      );
    });
    this.genericSlideUpAnimation(
      this.$newsPreview,
      '.page-list-title, .see-all, .event-list-titles, .event-list-item, .page-list-arrow'
    );

    this.lazyLoadImages();
  };

  genericSlideUpAnimation = ($el, selectors, cb, showLine) => {
    if (this.checkViewport($el)) {
      $el.addClass('animated');
      const tl = new TimelineMax();
      tl.set($el, { autoAlpha: 1 }, 0).staggerFrom(
        $el.find(selectors),
        1.5,
        { y: 30, autoAlpha: 0, ease: Power3.easeOut },
        0.05,
        0.5
      );
      if (showLine) {
        tl.from(
          $el.find('.line > i'),
          1,
          { scaleX: 0, ease: Power3.easeOut },
          1
        );
      }
      tl.eventCallback('onComplete', () => {
        if (cb) {
          cb();
        }
      });
    }
  };

  lazyLoadImages = () => {
    this.lazyImage.each((index, el) => {
      const $this = $(el);
      const imageSrc = $this.data('src');
      const alt = $this.data('alt');
      if (this.checkViewport($this, -100)) {
        $this.addClass('animated');
        downloadLazyImage(imageSrc, success => {
          if (success) {
            $this.append(`<img src="${imageSrc}" alt="${alt}" />`);
            // const tl = new TimelineMax();
            // tl.from($this.find('img'), 1, { autoAlpha: 0, ease: Power3.easeOut }, 1);
          }
        });
      }
    });
  };
}

new IsInViewportComponent();
