import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class NewsSlider {
  constructor(container) {
    this.$container = container;
    this.init();
  }

  init = () => {
    this.initSlider();
    this.bindEvents();
  };

  initSlider = () => {
    if (this.$container.find('.event-list-listing').length) {
      this.$container.find('.event-list-listing').slick({
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        dots: false,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        asNavFor: this.$container.find('.event-list-titles'),
        // responsive: [
        //   {
        //     breakpoint: 767,
        //     settings: {
        //       // asNavFor: null
        //     }
        //   }
        // ]
        responsive: [
          {
          breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              variableWidth: true,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              // speed: 300,
              variableWidth: true,
              // centerMode: true
            } 
          }
        ] 
      });
    }

    this.$container.find('.event-list-titles').slick({
      infinite: true,
      slidesToShow: 1,
      arrows: false,
      dots: true,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
      asNavFor: this.$container.find('.event-list-listing').length
        ? this.$container.find('.event-list-listing')
        : null,
      // responsive: [
      //   {
      //     breakpoint: 767,
      //     settings: {
      //       // asNavFor: null,
      //       fade: false,
      //       slidesToShow: 1,
      //       cssEase: 'ease'
      //     }
      //   }
      // ]
      responsive : [
        {
          breakpoint: 767,
          settings: {
            fade: false,
            cssEase: 'ease',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            
          }
        }
      ]
    });
  };

  bindEvents = () => {
    this.$container.on('click', '.page-list-arrow .right', () => {
      this.$container.find('.event-list-titles').slick('slickNext');
    });

    this.$container.on('click', '.page-list-arrow .left', () => {
      this.$container.find('.event-list-titles').slick('slickPrev');
    });
  };
}

if ($('.news-preview-slider').length) {
  new NewsSlider($('.news-preview-slider'));
}
