// import { TimelineMax, TweenMax, Power3 } from 'gsap/all';
import { parseQS, attachFavHandler } from './utils';
import Maps from './Maps';

class Search {
  constructor($container) {
    this.$container = $container;
    this.$filterContainer = $container.find('.listing');

    this.$searchInput = $container.find('.search-filter');
    this.$searchButton = $container.find('.search-btn');

    this.$sortFilter = $container.find('.sort-filter');
    this.$categoryFilter = $container.find('.category-filter');
    this.$featureFilter = $container.find('.feature-filter');
    this.$typeFilter = $container.find('.type-filter');
    this.$pagination = $container.find('.pagination');
    this.$mapContainer = $container.find('.show-map');
    this.$footer = $('.footer');
    this.state = {
      loading: true,
      showMore: true,
      replace: true,
      sort: '',
      page: 1,
      keyword: '',
      category: '',
      feature: '',
      type: ''
    };
    this.debounce = null;
    this.delay = 1000;
    this.init();
    this.mapHandler = this.$mapContainer.length ? new Maps({}) : null;
    const isLocalhost = window.location.origin.indexOf('localhost') >= 0;
    this.apiUrl = isLocalhost
      ? 'http://localhost/digital-city-c5/index.php'
      : window.location.origin;
    // console.log(this.mapHandler, CCM_REL);
    // Uncomment below for infinite scroll
    // ['resize', 'scroll'].forEach(e => {
    //   window.addEventListener(e, this.watchBottom);
    // });
  }

  checkViewport = ($el, offset) => {
    return $el && $el.length && $el.isInViewport(offset);
  };

  watchBottom = () => {
    if (this.state.loading || !this.state.showMore) {
      return;
    }
    if (this.state.showMore) {
      this.filterResults();
    }
    // Uncomment below for infinite scroll
    // if (this.checkViewport(this.$footer)) {
    //   if (this.state.showMore) {
    //     this.filterResults();
    //   }
    // }
  };

  init = () => {
    const qs = parseQS(window.location.search);
    const keys = Object.keys(qs);

    keys.map(key => {
      this.state[key] = qs[key];
      switch (key) {
        case 'keyword':
          this.$searchInput.val(qs[key]);
          break;
        case 'page':
          this.handlePagination();
          break;
        case 'category':
          this.$categoryFilter.val(qs[key]);
          break;
        case 'feature':
          this.$featureFilter.val(qs[key]);
          break;
        case 'type':
          this.$typeFilter.val(qs[key]);
          break;
        case 'sort':
          this.$sortFilter.val(qs[key]);
          break;
      }
    });

    this.filterResults();
    this.bindEvents();
  };

  handlePagination = () => {
    const $page = this.$pagination.find(`li[data-page="${this.state.page}"]`);
    $page.siblings().removeClass('active');
    $page.addClass('active');
  };

  bindEvents = () => {
    this.$searchInput.on('keyup', e => {
      console.log(e.target.value);
      if (e.which === 13) {
        this.submit(e.target.value);
      }
    });

    this.$searchButton.on('click', e => {
      this.submit(this.$searchInput.val());
    });

    this.$pagination.on('click', 'li', e => {
      const $this = $(e.currentTarget);
      const page = $this.attr('data-page');
      if (this.state.page !== page) {
        this.state.page = Number(page);
        this.watchBottom();
        $this.siblings().removeClass('active');
        $this.addClass('active');
        this.updatePageUrl();
      }
    });

    if (this.$categoryFilter.length) {
      this.$categoryFilter.on('change', e => {
        this.state.category = e.target.value;
        this.state.page = 1;
        this.updatePageUrl(true);
        this.filterResults();
      });
    }

    if (this.$featureFilter.length) {
      this.$featureFilter.on('change', e => {
        this.state.feature = e.target.value;
        this.state.page = 1;
        this.updatePageUrl(true);
        this.filterResults();
      });
    }

    if (this.$typeFilter.length) {
      this.$typeFilter.on('change', e => {
        this.state.type = e.target.value;
        this.state.page = 1;
        this.updatePageUrl(true);
        this.filterResults();
      });
    }

    if (this.$sortFilter.length) {
      this.$sortFilter.on('change', e => {
        this.state.sort = e.target.value;
        // this.state.page = 1;
        this.updatePageUrl(true);
        this.filterResults();
      });
    }
  };

  submit = val => {
    this.state.keyword = val;
    this.state.page = 1;
    this.state.replace = true;
    this.updatePageUrl();
    this.filterResults();
  };

  updatePageUrl = () => {
    const { category, page, keyword, feature, type, sort } = this.state;
    window.history.replaceState(
      { q: keyword },
      'qs',
      `?keyword=${keyword}&page=${Number(page)}${
        category ? '&category=' + category : ''
      }${feature ? '&feature=' + feature : ''}${type ? '&type=' + type : ''}${
        sort ? '&sort=' + sort : ''
      }
					`
    );
  };

  filterResults = () => {
    this.loadingHandler();
    this.state.loading = true;
    const { category, page, keyword, feature, type, sort } = this.state;

    const data = {
      ...(category && { category }),
      ...(feature && { feature }),
      ...(type && { type }),
      ...(keyword && { keyword }),
      ...(page && { page }),
      ...(sort && { sort }),
      isAjax: true
    };

    $.ajax({
      dataType: 'html',
      type: 'GET',
      data,
      url: `${window.location.origin}${window.location.pathname}`,
      success: this.successHandler
    });
  };

  loadingHandler = () => {
    const spinner =
      '<div class="flex-spinner"><div class="spinner"></div></div>';
    if (this.state.replace) {
      this.$filterContainer.html(spinner);
    } else {
      this.$filterContainer.append(spinner);
    }
  };

  successHandler = response => {
    response = JSON.parse(response);
    if (response.success) {
      if (response.empty) {
        this.state.page = 1;
        this.$filterContainer.find('.flex-spinner').remove();
        this.$filterContainer.html(
          `<h4 class="not-found">${response.message}</h4>`
        );
        this.handlePagination();
        if (this.mapHandler) {
          this.mapHandler.setMapCoordinates($('.map-item'));
        }
        return;
      }
      // this.state.page += 1;
      this.$filterContainer.find('.flex-spinner').remove();
      this.$filterContainer.find('.not-found').remove();
      if (this.state.replace) {
        this.$filterContainer.html(response.data);
      } else {
        this.$filterContainer.append(response.data);
      }

      TweenMax.set(this.$filterContainer, { autoAlpha: 0 }, 0);
      this.animate();
      this.state.loading = false;
      this.state.showMore = true;
      this.state.replace = true;
      this.state.count = response.count >= 1 ? response.count : 1;
      this.state.page = response.count < 1 ? 1 : this.state.page;
      this.drawPagination();
      if (this.mapHandler) {
        this.mapHandler.setMapCoordinates($('.map-item'));
      }
    }
  };

  drawPagination = () => {
    let html = '';
    for (let index = 1; index <= this.state.count; index++) {
      html += `<li data-page="${index}">${index}</li>`;
    }
    this.$pagination.find('ul').html(html);
    setTimeout(() => {
      this.handlePagination();
      this.updatePageUrl();
    }, 500);
  };

  animate = () => {
    const tl = new TimelineMax();
    tl.set(this.$filterContainer, { autoAlpha: 1 }, 0).staggerFrom(
      this.$filterContainer.find('> div:not(.animated)'),
      1.5,
      { y: 30, autoAlpha: 0, ease: Power3.easeOut },
      0.05,
      0.5
    );
    tl.eventCallback('onComplete', () => {
      this.$filterContainer.find('> div').addClass('animated');
      attachFavHandler();
    });
  };
}

if ($('.page-listing-filter').length) {
  new Search($('.page-listing-filter'));
}
