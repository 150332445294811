import loadGoogleMapsApi from 'load-google-maps-api';
import { mapStyle, mapOptions } from './constants';

export default class Maps {
  constructor({ forceDraw = false, element, lat, lng }) {
    this.mapContainer = document.getElementById('map_canvas');
    this.lat = lat ? lat : 24.740393;
    this.lng = lng ? lng : 46.6332253;
    this.mapIconUrl = `${CCM_REL}/application/themes/digitalcity/src/images/${
      forceDraw ? 'marker-big' : 'marker'
    }.svg`;
    this.mapCoordinates = [];
    this.mapCoordinatesData = {};
    this.forceDraw = forceDraw;
    if (this.mapContainer) {
      loadGoogleMapsApi({
        key: 'AIzaSyAfxQST26oog1u0CfAtwI3tn6prwtUf7a8'
        // key: ''
      }).then(google => {
        this.google = google;
        this.init(google);
        if (forceDraw) {
          this.setMapCoordinates(element);
        }
      });
    }
  }

  setMapCoordinates = $el => {
    this.clearMarkers();
    this.mapCoordinates = [];
    this.mapCoordinatesData = {};
    $el.each((index, el) => {
      const $this = $(el);
      this.mapCoordinates = [
        ...this.mapCoordinates,
        {
          lat: $this.data('lat'),
          lng: $this.data('lng'),
          thumbnail: $this.data('thumbnail'),
          title: $this.data('title'),
          subtitle: $this.data('subtitle'),
          isOpen: $this.data('open'),
          $el: $this
        }
      ];
    });
    // console.log(this.mapCoordinates);
    this.drawMapLocations();
  };

  clearMarkers = () => {
    const max = this.mapCoordinates.length;
    for (var i = 0; i <= max; i++) {
      if (this[`marker-${i}`]) {
        this[`marker-${i}`].setMap(null);
      }
    }
  };

  drawMapLocations = () => {
    this.mapCoordinates.map((item, index) => {
      const { lat, lng, thumbnail, title, subtitle, isOpen, $el } = item;
      const latLng = new this.google.LatLng(lat, lng);
      // this.myLatlng = new google.LatLng(lat,lng);
      this[`marker-${index}`] = new this.google.Marker({
        position: latLng,
        map: this.map,
        animation: this.google.Animation.DROP,
        icon: this.mapIconUrl,
        title: 'Maps'
      });
      const htmlContent = `
        <div class="bordered-hover no-link">
          ${
            thumbnail
              ? `<div class="flex">
            <img class="thumbnail" src="${thumbnail}"  />
          </div>`
              : ''
          }
          <div class="content hover-container">
          <h4 class="h6 has-badge "><span>${title} <div class="badge ${
        !!isOpen ? '' : 'hidden'
      } ${isOpen ? 'badge-open' : 'badge-closed'}"></div></span> <br />
          ${subtitle ? '<b>' + subtitle + '</b>' : ''}
          </h4>
          </div>
        </div>
      `;
      const showInfo = () => {
        this.infowindow.setContent(
          `<div" class="common-listing-block map-listing-item">${htmlContent}</div>`
        );
        this.infowindow.open(this.map, this[`marker-${index}`]);
        this.map.panTo(this[`marker-${index}`].getPosition());
      };

      const hideInfo = () => {
        this.infowindow.close();
      };

      this[`marker-${index}`].addListener('click', () => {
        showInfo();
      });
      if (this.forceDraw) {
        showInfo();
      } else {
        $el.on({
          mouseover: () => showInfo(),
          mouseleave: () => hideInfo()
        });
      }
    });
  };

  init = google => {
    // console.log(this.lat, this.lng);
    this.mapCenter = new google.LatLng(this.lat, this.lng);
    this.map = new google.Map(this.mapContainer, {
      ...mapOptions(google),
      ...{
        center: this.mapCenter,
        styles: mapStyle
      }
    });
    this.infowindow = new google.InfoWindow({});
  };

  bindEvents = () => {
    this.google.event.addListener(this.marker, 'click', this.toggleBounce);
  };

  toggleBounce = markerClicked => {
    if (markerClicked.getAnimation() != null) {
      markerClicked.setAnimation(null);
    } else {
      markerClicked.setAnimation(this.google.Animation.BOUNCE);
    }
  };
}
