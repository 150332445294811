import CommonSlider from './CommonSlider';
import { attachFavHandler } from './utils';

export default class Sidebar {
  constructor(args) {
    console.log(args);
    this.$container = args.container;
    this.searchEnabled = args.search;
    this.$searchInput = this.$container.find('.search');
    this.$searchButton = this.$container.find('.search-btn');
    this.$emptyLoad = this.$container.find('.empty-load');
    // this.$favEmptyLoad = this.$container.find('.fav-empty-load');
    this.$searchLoader = this.$container.find('.sidebar__loader');
    this.$searchEmpty = this.$container.find('.sidebar__empty');
    this.$lifestyleContainer = this.$container.find('[data-type="lifestyle"]');
    this.$companiesContainer = this.$container.find('[data-type="companies"]');
    this.$residentialContainer = this.$container.find(
      '[data-type="residential"]'
    );
    this.$realestateContainer = this.$container.find(
      '[data-type="realestate"]'
    );
    this.$sidebarBody = this.$container.find('.sidebar__body');

    const isLocalhost = window.location.origin.indexOf('localhost') >= 0;
    this.apiUrl = isLocalhost
      ? 'http://localhost/digital-city-c5/index.php'
      : window.location.origin;
    this.state = {
      keyword: '',
      loading: false
    };
    if (this.searchEnabled) {
      this.bindEvents();
    }
    attachFavHandler(!this.searchEnabled ? this.getFavourites : null);
  }

  bindEvents = () => {
    this.$searchButton.on('click', e => {
      e.preventDefault();
      this.filterResults();
    });
    this.$searchInput.on('keyup', e => {
      this.state.keyword = e.target.value;
      if (e.which === 13) {
        this.filterResults();
      }
    });
  };

  filterResults = () => {
    if (this.state.loading) {
      return;
    }
    this.state.loading = true;
    this.$sidebarBody.removeClass('active');
    this.$searchEmpty.removeClass('active');
    this.$searchLoader.addClass('active');  
    this.$emptyLoad.removeClass('active');
    $.ajax({
      dataType: 'html',
      type: 'GET',
      data: {
        keyword: this.state.keyword
      },
      url: `${this.apiUrl}/api/v1/search`,
      success: this.successHandler
    });
  };

  successHandler = response => {
    const json = JSON.parse(response);

    const {
      data: { lifestyle, companies, residential, realestate }
    } = json;
    const isEmpty =
      !lifestyle.length &&
      !companies.length &&
      !residential.length &&
      !realestate.length;
    if (isEmpty) {
      this.$searchEmpty.addClass('active');
      // this.$favEmptyLoad.addClass('active');
      this.$searchLoader.removeClass('active');
      this.state.loading = false;
      return;
    }
    this.$searchEmpty.removeClass('active');
    this.$sidebarBody.addClass('active');
    // this.$favEmptyLoad.removeClass('active');
    this.$searchLoader.removeClass('active');
    this.$lifestyleContainer.removeClass('active');
    this.$companiesContainer.removeClass('active');
    this.$residentialContainer.removeClass('active');
    this.$realestateContainer.removeClass('active');

    if (this.lifestyleSlider) {
      this.lifestyleSlider.unslick();
    }
    if (this.companiesSlider) {
      this.companiesSlider.unslick();
    }
    if (this.residentialSlider) {
      this.residentialSlider.unslick();
    }
    if (this.realestateSlider) {
      this.realestateSlider.unslick();
    }
    this.state.loading = false;
    if (lifestyle.length) {
      this.$lifestyleContainer
        .addClass('active')
        .find('.sidebar__slider')
        .html(lifestyle);

      this.lifestyleSlider = new CommonSlider(this.$lifestyleContainer, {
        infinite: false
      });
    }
    console.log(lifestyle);
    console.log(companies);
    console.log(residential);
    console.log(realestate);
    
    if (companies.length) {
      this.$companiesContainer
        .addClass('active')
        .find('.sidebar__slider')
        .html(companies);
      this.companiesSlider = new CommonSlider(this.$companiesContainer, {
        infinite: false
      });
    }

    if (residential.length) {
      this.$residentialContainer
        .addClass('active')
        .find('.sidebar__slider')
        .html(residential);
      this.residentialSlider = new CommonSlider(this.$residentialContainer, {
        infinite: false
      });
    }

    if (realestate.length) {
      this.$realestateContainer
        .addClass('active')
        .find('.sidebar__slider')
        .html(realestate);
      this.realestateSlider = new CommonSlider(this.$realestateContainer, {
        infinite: false
      });
    }

    attachFavHandler(!this.searchEnabled ? this.getFavourites : null);
  };

  getFavourites = () => {
    console.log('get me');
    if (this.state.loading) {
      return;
    }
    this.state.loading = true;
    this.$sidebarBody.removeClass('active');
    this.$searchLoader.addClass('active');
    // this.$favEmptyLoad.removeClass('active');
    $.ajax({
      dataType: 'html',
      type: 'GET',
      url: `${this.apiUrl}/api/v1/favourites`,
      success: this.successHandler
    });
  };
}

// console.log('asdasd');
new Sidebar({
  container: $('.search-enabled'),
  search: true
});
