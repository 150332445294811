import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class CommonSlider {
  constructor(container, options) {
    this.options = options;
    this.$container = container;
    this.init();
    
  }

  init = () => {
    this.initSlider();
    this.bindEvents();
  };
   
 
  initSlider = () => {
    this.$container.find('.common-preview-slider-listing').slick({
      infinite: true,
      // slidesToShow: 1,
      arrows: false,
      dots: false,
      slidesToScroll: 1,
      variableWidth: true,
      rtl: this.rtl_slick(),
      loop: true,
      ...this.options
    
    });
  };
  
  rtl_slick = () =>   {
    if ($('body').hasClass('page-arabic')) {
      return true;
    } else {
      return false;
    }
  }
 
  bindEvents = () => {
    this.$container.on('click', '.page-list-arrow .right', () => {
      this.$container.find('.common-preview-slider-listing').slick('slickNext');
    });

    this.$container.on('click', '.page-list-arrow .left', () => {
      this.$container.find('.common-preview-slider-listing').slick('slickPrev');
    });
  };

  unslick = () => {
    this.$container.find('.common-preview-slider-listing').slick('unslick');
  };
}

if ($('.common-preview-slider').length) {
  $('.common-preview-slider').each((i, el) => {
    new CommonSlider($(el));
  });
}

class CommonImageSlider {
  constructor(container) {
    this.$container = container;
    this.htmlBody = $('body');
    this.init();
  }

  init = () => {
    this.initSlider();
    this.bindEvents();
  };

  initSlider = () => {
    if(this.htmlBody.hasClass('page-arabic')) {
      this.$container.slick({
        infinite: true,
        // slidesToShow: 1,
        arrows: false,
        dots: true,
        slidesToScroll: 1,
        rtl: true
      });
    } else {
      this.$container.slick({
        infinite: true,
        arrows: false,
        dots: true,
        slidesToScroll: 1
      });
    }
  };

  bindEvents = () => {
    this.$container.on('click', '.page-list-arrow .right', () => {
      this.$container.find('.common-preview-slider-listing').slick('slickNext');
    });

    this.$container.on('click', '.page-list-arrow .left', () => {
      this.$container.find('.common-preview-slider-listing').slick('slickPrev');
    });
  };
}

if ($('.common-image-slider').length) {
  $('.common-image-slider').each((i, el) => {
    new CommonImageSlider($(el));
  });
}

class BannerImageSlider {
  constructor(container) {
    this.$container = container;
    this.init();
  }

  init = () => {
    this.initSlider();
  };

  initSlider = () => {
    this.$container.on('init', () => this.$container.addClass('active'));
    this.$container.slick({
      draggable: false,
      arrows: false,
      dots: true,
      fade: true,
      speed: 900,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 7000,
      cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
      touchThreshold: 100,
      lazyLoad: 'ondemand'
      // variableWidth: true,
    });
  };
}

if ($('.banner__slider').length) {
  $('.banner__slider').each((i, el) => {
    new BannerImageSlider($(el));
  });
}
