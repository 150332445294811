import Sidebar from './Sidebar';
export default class Header {
	constructor({ header, htmlBody }) {
		this.header = header;
		this.htmlBody = htmlBody;
		this.mobileMenu = this.header.find('.menu-mobile');

		this.mobileNav = this.htmlBody.find('.navigation-menu-mobile');
		this.closeMenu = this.mobileNav.find('.close');
		this.mobileMenuMask = this.htmlBody.find('.mobile-menu-mask');
		this.appWrapper = this.htmlBody.find('.app-wrapper');
		this.appSidebarLeft = this.htmlBody.find('.app-sidebar-left');
		this.appSidebarLeftFav = this.htmlBody.find('.app-sidebar-left-fav');
		this.appSidebarLeftSearch = this.htmlBody.find('.app-sidebar-left-search');
		this.appSidebarRight = this.htmlBody.find('.app-sidebar-right');

		this.enquiryToggle = this.htmlBody.find('.enquiry-sidebar__tail');
		this.favToggle = this.htmlBody.find('.toggle-fav');
		this.searchToggle = this.htmlBody.find('.toggle-search');

		this.closeSidebar = this.htmlBody.find('.sidebar__close');
		this.closeEnquirySidebar = this.htmlBody.find('.enquiry-sidebar__close');

		this.bindEvents();
		this.sidebarHandler = new Sidebar({
			container: $('.fav-enabled'),
			search: false,
		});
	}

	bindEvents = () => {
		this.mobileMenu.on('click', this.handleMobileMenu);
		this.closeMenu.on('click', this.handleMobileMenu);

		this.favToggle.on('click', () => {
			this.sidebarHandler.getFavourites();
			this.appSidebarLeftFav.addClass('active');
			this.appWrapper.addClass('active');
			this.htmlBody.addClass('prevent-overflow');
		});

		this.searchToggle.on('click', () => {
			this.appSidebarLeftSearch.addClass('active');
			this.appWrapper.addClass('active');
			this.htmlBody.addClass('prevent-overflow');
		});

		this.closeSidebar.on('click', () => {
			this.appSidebarLeft.removeClass('active');
			this.appWrapper.removeClass('active');
			this.htmlBody.removeClass('prevent-overflow');
		});

		this.enquiryToggle.on('click', () => {
			this.enquiryToggle.addClass('active');
			this.appSidebarRight.addClass('active');
			this.appWrapper.addClass('active-left');
			this.htmlBody.addClass('prevent-overflow');
		});

		this.closeEnquirySidebar.on('click', () => {
			this.enquiryToggle.removeClass('active');
			this.appSidebarRight.removeClass('active');
			this.appWrapper.removeClass('active-left');
			this.htmlBody.removeClass('prevent-overflow');
		});
	};

	handleMobileMenu = () => {
		if (this.mobileMenu.hasClass('active')) {
			this.mobileMenu.removeClass('active');
			this.htmlBody.removeClass('active');
			this.mobileNav.removeClass('active');
			this.mobileMenuMask.removeClass('active');
		} else {
			this.mobileMenu.addClass('active');
			this.htmlBody.addClass('active');
			this.mobileNav.addClass('active');
			this.mobileMenuMask.addClass('active');
		}
	};
}
